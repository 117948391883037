import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import '../assets/slick.scss';
import '../assets/slick-theme.scss';
import ProductTile from './productTile/ProductTile';
import LeftArrow from './customArrows/LeftArrow';
import RightArrow from './customArrows/RightArrow';
import styles from './ProductSetCarousel.module.scss';
import './ProductSetCarousel.scss';
import { connect, shallowEqual, useDispatch } from 'react-redux';
import { requestProductSet } from '../behaviour/actions';
import { throttle, debounce } from 'lodash';
import {
  useProductDefaultImages,
  parseSortOption,
  useLoadEffect,
  useHasAbilities,
  useMatchMediaContext,
} from 'sana/utils';
import { getDefaultProductList, getPageInfo , getStyles } from '../utils/constants';
import classNames from 'classnames';
import { AbilityTo } from 'sana/constants';
import { DomHead } from 'sana/elements';

const ProductSetCarousel = ({
  model,
  id,
  productSet,
  requestProductSet,
}) => {
  const modelRef = useRef();
  let modelExpired = false;
  if (modelRef.current && !shallowEqual(modelRef.current, model))
    modelExpired = true;

  modelRef.current = model;

  const {
    previousNavigatorIcon,
    nextNavigatorIcon,
    productSetValue,
    sortOption,
  } = modelRef.current;

  const [canViewCatalog] = useHasAbilities(AbilityTo.ViewCatalog);
  const { medium } = useProductDefaultImages();

  const mainComponentRef = useRef(null);
  const [sliderRef, setSliderRef] = useState(null);

  const pageInfo = getPageInfo(useMatchMediaContext(), modelRef.current);

  const dispatch = useDispatch();

  const [productList, setProductList] = useState([]);
  const [isNextClicked, setIsNextClicked] = useState(false);

  const [pageMeta, setPageMeta] = useState({
    currentPage: 0,
    nextPage: 1,
    numberOfPage: 0,
    perPageItem: pageInfo.itemPerRow * 2,
    totalCount: 0,
    isLoading: false,
  });

  const [sliderMeta, setSliderMeta] = useState({
    index: 0,
    disabledNext: false,
    disabledPrev: true,
    currentSlide: 0,
    hideNavigation: false,
  });

  const navigationIconStatus = slidValue => {
    let disabledPrev,
      disabledNext = false;
    if (slidValue <= 0)
      disabledPrev = true;

    if (productSet.products.totalCount <= slidValue + pageInfo.itemPerRow)
      disabledNext = true;
    setSliderMeta({
      ...sliderMeta,
      disabledPrev,
      disabledNext,
    });

    if (isNextClicked)
      nextAction();
    else
      prevAction();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: pageInfo.itemPerRow,
    slidesToScroll: pageInfo.itemPerRow,
    afterChange: current => navigationIconStatus(current),
  };

  useLoadEffect(()=>{
    if(canViewCatalog){
        resetStates();
        requestPageData(
          id,
          productSetValue,
          sortOption,
          pageInfo.itemPerRow * 2,
          0,
          true,
        );
    }

  },[canViewCatalog, modelExpired, pageInfo && pageInfo.itemPerRow]);

  const renderSlider = productList => {
    let element = [];
    for (let index = sliderMeta.index; index < productList.length; index++) {
      element = element.concat(
        <ProductTile key={index} product={productList[index]} showRatings={modelRef.current.showRatings} noImage={medium} />,
      );
    }
    return element;
  };

  const slideList = useMemo(() => {
    return renderSlider(!productList?.length ? productSet ? productSet.products.products : [] : productList);
  }, [productSet, productList]);

  useEffect(() => {
    if (productSet) {
      setPageMeta({
        ...pageMeta,
        totalCount: productSet.products.totalCount,
        numberOfPage: Math.round(
          parseInt(productSet.products.totalCount) / pageInfo.itemPerRow,
        ),
        perPageItem: pageInfo.itemPerRow,
      });

      setSliderMeta({
        ...sliderMeta,
        currentSlide: parseInt(sliderMeta.currentSlide + settings.slidesToShow),
        disabledPrev: false,
        hideNavigation: pageInfo.itemPerRow >= pageMeta.totalCount,
      });
      const exitingProduct = productList.filter(item => item.id !== undefined);
      setProductList(() => exitingProduct.concat(productSet.products.products));
    }
  }, [productSet]);

  const navigateToFirstSlide = debounce(() => {
    sliderRef.slickGoTo(0);
  },1000);

  useEffect(() => {
    if (pageMeta.currentPage === 0 && sliderRef)
    navigateToFirstSlide();
    
  }, [sliderRef && sliderRef.props.children]);

  useEffect(() => {
    let disabledNext,
      disabledPrev = false;
    if (pageMeta.totalCount <= settings.slidesToShow) {
      disabledNext = true;
      disabledPrev = true;
    } else if (sliderMeta.currentSlide >= pageMeta.totalCount)
      disabledNext = true;
    else if (sliderMeta.currentSlide === settings.slidesToShow)
      disabledPrev = true;

    setSliderMeta({
      ...sliderMeta,
      disabledNext,
      disabledPrev,
      hideNavigation: pageInfo.itemPerRow >= pageMeta.totalCount,
    });
  }, [sliderMeta.currentSlide, pageMeta]);

  useEffect(() => {
    setPageMeta({
      ...pageMeta,
      isLoading: false,
    });
    renderSlider(productList);
  }, [productList]);

  const resetStates = useCallback(() => {
    setProductList(getDefaultProductList(pageInfo.itemPerRow));
    setPageMeta({
      currentPage: 0,
      nextPage: 1,
      numberOfPage: 0,
      perPageItem: pageInfo.itemPerRow * 2,
      totalCount: 0,
      isLoading: false,
    });
    setSliderMeta({
      index: 0,
      disabledNext: false,
      disabledPrev: true,
      currentSlide: 0,
      hideNavigation: false,
    });
  }, []);

  const requestPageData = useCallback(
    (id, productSetValue, sortOption, perPageItem, page, init = false) => {
      const sorting = sortOption ? [parseSortOption(sortOption)] : [];

      if (page >= 1)
        page = page + 1;

      dispatch(
        requestProductSet(id, productSetValue, sorting, perPageItem, page),
      );
      if (init) {
        setProductList(productList =>
          productList.concat(getDefaultProductList(pageInfo.itemPerRow)),
        );
      }
    },
    [modelExpired, pageMeta],
  );

  const nextAction = throttle(() => {
    if (
      productList.length - sliderMeta.currentSlide ===
      settings.slidesToShow
    ) {
      if (pageMeta.currentPage !== pageMeta.numberOfPage) {
        const nextPage = pageMeta.nextPage + 1;
        setPageMeta({
          ...pageMeta,
          nextPage,
          currentPage: pageMeta.nextPage,
          isLoading: true,
        });
      }
      requestPageData(
        id,
        productSetValue,
        sortOption,
        settings.slidesToShow,
        pageMeta.nextPage,
      );
    } else {
      setSliderMeta({
        ...sliderMeta,
        currentSlide: parseInt(sliderMeta.currentSlide + settings.slidesToShow),
        disabledPrev: false,
      });
    }
  }, 1000);

  const prevAction = throttle(() => {
    setSliderMeta({
      ...sliderMeta,
      currentSlide: parseInt(sliderMeta.currentSlide - settings.slidesToShow),
      disabledPrev: false,
    });
  }, 1000);

  return (
    <>
      <DomHead content={getStyles(id, model)} />
      <div id={`ssr-${id}`}
        className={classNames(
          'product-set-carousel-content-block',
          styles.root,
        )}
        ref={mainComponentRef}
      >
        <div className={styles.sliderContent}>
          {
            <Slider ref={c => setSliderRef(c)} {...settings}>
              {slideList}
            </Slider>
          }
          <div className={styles.navigation}>
            {!sliderMeta.hideNavigation && (
              <>
                <LeftArrow
                  imagePath={previousNavigatorIcon}
                  onClick={() => {
                    const _sliderRef = sliderRef;
                    _sliderRef.slickPrev();
                    setIsNextClicked(false);
                  }}
                  disabled={sliderMeta.disabledPrev}
                />
                <RightArrow
                  imagePath={nextNavigatorIcon}
                  onClick={event => {
                    const _sliderRef = sliderRef;
                    _sliderRef.slickNext();
                    setIsNextClicked(true);
                  }}
                  disabled={sliderMeta.disabledNext}
                  apiLoading={pageMeta.isLoading}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ProductSetCarousel.propTypes = {
  model: PropTypes.shape({
    previousNavigatorIcon: PropTypes.string,
    nextNavigatorIcon: PropTypes.string,
    productSetValue: PropTypes.string,
    sortOption: PropTypes.string,
    productsOnExtraLargeScreen: PropTypes.number,
    productsOnExtraSmallScreen: PropTypes.number,
    productsOnLargeScreen: PropTypes.number,
    productsOnMediumScreen: PropTypes.number,
    productsOnSmallScreen: PropTypes.number,
  }),
  id: PropTypes.string.isRequired,
  productSet: PropTypes.object,
  requestProductSet: PropTypes.func.isRequired,
};

const mapStateToProps = ({ items }, { id }) => {
  const productSet = items && items[id];

  return {
    productSet,
  };
};

export default connect(mapStateToProps, { requestProductSet })(ProductSetCarousel);
